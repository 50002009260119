
import { Component, Prop, Vue } from 'vue-property-decorator'
import { apiOrderPrint } from '@/api/application/print'
import LsDialog from '@/components/ls-dialog.vue'
import LsPagination from '@/components/ls-pagination.vue'
import OrderLogistics from '@/components/order/business-order-logistics.vue'
import { apiOrderRemarks, apiOrderCancel, apiOrderConfirm, apiOrderConfirmpay } from '@/api/goods_order/business_order'
import { apiSelffetchVerification } from '@/api/application/selffetch'
@Component({
    components: {
        LsDialog,
        LsPagination,
        OrderLogistics
    }
})
export default class OrderPane extends Vue {
    $refs!: { paneTable: any; verifyDialogRef: any }
    @Prop() value: any
    @Prop() pager!: any

    selectIds: any = []

    remarks = '' //商家备注

    verifyTips = ''

  //获取结算金额
  get getTotalMoney() {
   return  (this.$parent as any).getTotalMoney()
  }

  //获取总运费
  get  getTotalExpressMoney() {
    return (this.$parent as any).getTotalExpressMoney()
  }
    // 获取订单信息
    getOrderLists() {
        ;(this.$parent as any).getOrderLists()
    }

    // 选择某条数据
    selectionChange(val: any[]) {
        this.selectIds = val.map(item => item.id)
    }

    // 全选
    selectAll() {
        ;(this.$refs.paneTable as any).toggleAllSelection()
    }

    // 去订单详情
    toOrder(id: any) {
        this.$router.push({
            path: '/goodsorder/business_order_detail',
            query: { id }
        })
    }

    toUser(id: any) {
        this.$router.push({
            path: '/user/user_details',
            query: { id: id }
        })
    }

    // 取消订单
    orderCancel(id: Number) {
        apiOrderCancel({ id: id }).then(res => {
            this.getOrderLists()
        })
    }

    // 确认收货
    orderConfirm(id: Number) {
        apiOrderConfirm({ id: id }).then(res => {
            this.getOrderLists()
        })
    }
    orderConfirmpay(id: Number) {
        apiOrderConfirmpay({ id: id }).then(res => {
            this.getOrderLists()
        })
    }

    async onPrintOrderFunc(row: any) {
        await apiOrderPrint({ id: row.id })
    }

    // 商家备注
    postOrderRemarks(id: any) {
        id = Array.isArray(id) == true ? id : this.selectIds
        apiOrderRemarks({
            id: id,
            order_remarks: this.remarks
        }).then(res => {
            this.remarks = ''
            this.getOrderLists()
        })
    }

    // 订单核销
    onSelffetchOrderVerification(id: number, isConfirm = 0) {
        apiSelffetchVerification({
            id,
            confirm: isConfirm
        }).then(res => {
            if (res.code === 10) {
                this.verifyTips = res.msg
                this.$nextTick(() => {
                    this.$refs.verifyDialogRef?.open()
                })
                return
            }
            this.getOrderLists()
        })
    }
}
